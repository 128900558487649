import { getCookie } from 'helpers/cookie';
import { appendOrAddQueryParam } from 'helpers/queryParam';
import type { ICollectionState } from 'store/slices/collection';
import { getTranslationKey } from './texting';

interface IIds {
  selection_id?: string;
  gallery_id?: string;
  image_id?: string;
  collection_id?: string;
}

interface ISettings {
  collection: ICollectionState;
  environment: any;
  quality?: any;
}

interface IReturnUrl {
  error?: any;
  url?: string;
  infoModalText: string;
  infoModalText2: string;
}

export const getDownloadUrl = (
  { selection_id, gallery_id, image_id, collection_id }: IIds,
  { collection, environment, quality }: ISettings
): IReturnUrl => {
  try {
    const { downloadPin, _id: id, password, downloadToken } = collection;
    const { isMobileDevice, isMobileSafari, iosVersion, isApp, isMobileChrome, isTest } =
      environment;

    let apiURLDL = window.API_URL_DL;

    if (isApp && iosVersion && iosVersion >= 13) {
      if (password && !downloadToken) {
        return {
          error: 'buggyDownloadIosApp',
          infoModalText: getTranslationKey('buggyDownloadIosApp'),
          infoModalText2: window.location.href
        };
      }
      if (isTest) {
        apiURLDL = 'https://api-dev.galleryapps.io';
      }
    }

    let url = '';
    if (selection_id) {
      url = `${apiURLDL}/api/download/collection/${id}/selection/${selection_id}`;
    } else if (gallery_id) {
      url = `${apiURLDL}/api/download/collection/${id}/gallery/${gallery_id}`;
    } else if (image_id) {
      url = `${apiURLDL}/api/download/image/${id}/${image_id}`;
    } else if (collection_id) {
      url = `${apiURLDL}/api/download/collection/${id}`;
    } else {
      throw 'No idetenifier set getDownloadUrl()';
    }

    const queryParams: Record<string, string | undefined> = {
      q: quality || 'high',
      downloadToken,
      endCustomerToken: getCookie('endCustomerToken') || undefined
    };
    if (downloadPin) {
      queryParams.downloadPin = downloadPin;
    }

    url = appendOrAddQueryParam({
      url,
      params: queryParams
    });

    let infoModalText;
    let infoModalText2;

    if (isMobileDevice) {
      if (isApp) {
        if (isMobileChrome) {
          infoModalText = getTranslationKey('downloadStarted');
        } else if (isMobileSafari && iosVersion && iosVersion >= 13) {
          infoModalText = getTranslationKey('downloadStartedIosApp');
        }
      } else if (isMobileSafari && iosVersion && iosVersion >= 13) {
        infoModalText = getTranslationKey('downloadStartedIos');
      }

      infoModalText2 = getTranslationKey('bigData');
    }

    return {
      url,
      infoModalText,
      infoModalText2
    };
  } catch (e) {
    console.log(e);

    // @ts-ignore
    return { error: e };
  }
};
