import { useState, ChangeEvent } from 'react';
import { useAppSelector, useAppDispatch } from 'hooks/redux';
import { toggleDownloadModal } from 'store/slices/modals';
import { useDownload } from 'hooks/useDownload';
import { getTranslationKey } from 'helpers/texting';
import { galleriesMapSelector } from 'old-store/selectors/gallery';

interface ISelectedItem {
  selection: string;
  gallery: string;
}

export const useDownloadData = () => {
  const dispatch = useAppDispatch();
  const collection = useAppSelector((state) => state.collection);
  const { images } = useAppSelector((state) => state.images);
  const { all: selections, likes } = useAppSelector((state) => state.selection);
  const { downloadModal } = useAppSelector((state) => state.modals);
  const galleriesMap = useAppSelector(galleriesMapSelector);
  const download = useDownload();

  const [typeSelected, setTypeSelected] = useState<string>('');
  const [selectedItem, setSelectedItem] = useState<ISelectedItem>({
    selection: '',
    gallery: ''
  });
  const { selection, gallery } = selectedItem;

  const galleries = collection?.galleries?.filter((gallery) => gallery.download);
  const allGalleriesAvailableForDownload = collection?.galleries?.every(
    (gallery) => gallery.download
  );
  const handleToggle = () => dispatch(toggleDownloadModal());

  const selectDownloadType = (e: ChangeEvent<HTMLSelectElement>) => setTypeSelected(e.target.value);

  const filterOptions = ({ value }) => {
    if (value === 'selection') return allGalleriesAvailableForDownload;
    if (value === 'gallery') return galleries.length >= 1;

    return true;
  };

  const menuOptions = [
    { name: getTranslationKey('shareView.completeGallery'), value: 'allImages' },
    { name: getTranslationKey('shareView.specificAlbum'), value: 'gallery' },
    { name: getTranslationKey('shareView.specificSelection'), value: 'selection' },
    { name: getTranslationKey('downloadModal.singleImage'), value: 'singleImage' }
  ].filter(filterOptions);

  const selectionOptions = selections.map(({ _id, name }) => ({
    name: name || getTranslationKey('selections.chooseSelectionModal.currentSelection'),
    value: _id
  }));

  const galleriesOptions = galleries.map(({ _id, title }) => ({ name: title, value: _id }));

  const selectDownloadItem = (type: string, id: string) =>
    setSelectedItem((prev) => ({ ...prev, [type]: id }));

  const downloadSelectedItem = (quality: string) => {
    const selectedTypeMap = {
      allImages: { collection_id: window.SITE_ID },
      gallery: { gallery_id: gallery },
      selection: { selection_id: selection }
    };

    download(selectedTypeMap[typeSelected], quality);
  };

  const isDownloadDisabled = () => {
    const isTypeSelected = Boolean(typeSelected);
    const isGallerySelected = Boolean(gallery);
    const isSelectionSelected = Boolean(selection);
    const isDownloadOfAllImagesAllowed = galleries.some((gallery) => gallery.download);

    const downloadAvailabilityMap = {
      allImages: isTypeSelected && isDownloadOfAllImagesAllowed,
      gallery: isTypeSelected && isGallerySelected,
      selection:
        isTypeSelected && isSelectionSelected && galleries.every((gallery) => gallery.download)
    };

    const disabledDownload = !downloadAvailabilityMap[typeSelected];

    return disabledDownload;
  };

  const isHighQualityAvailable = () => {
    const isHighQualityOfAllGalleriesAllowed = galleries.every((gallery) => !gallery._watermark);

    const selectedGallery = galleries.find(({ _id }) => _id === gallery);
    const isHighQualityOfGalleryAllowed = !selectedGallery?._watermark;

    const selectionImages = images.filter((image) => likes.includes(image._id));
    const selectionGalleriesIds = selectionImages.map((image) => image.gallery_id);
    const isHighQualityOfSelectionAllowed = selectionGalleriesIds.every(
      (id) => !galleriesMap[id]._watermark
    );

    const highQualityAvailabilityMap = {
      allImages: isHighQualityOfAllGalleriesAllowed,
      gallery: isHighQualityOfGalleryAllowed,
      selection: isHighQualityOfSelectionAllowed
    };

    return highQualityAvailabilityMap[typeSelected];
  };

  return {
    downloadModal,
    menuOptions,
    selectionOptions,
    galleriesOptions,
    selectDownloadItem,
    selectedItem,
    selectDownloadType,
    downloadSelectedItem,
    handleToggle,
    isDownloadDisabled,
    typeSelected,
    isHighQualityAvailable
  };
};
