import { createAsyncThunk } from '@reduxjs/toolkit';
import { range } from 'lodash';
import {
  setActiveImageId,
  updatePricing,
  updateSpreads,
  ISpreadImage,
  setPhotobookMode,
  setPhotobookCover,
  setLastUpdate,
  CoverPositions,
  setPhotobookCoverText,
  IPhotobookState,
  changePhotobookTitle,
  setStartingTitle
} from '.';

import { Api } from 'old-store/utils';
import { toast } from 'react-toastify';
import { getTranslationKey } from 'helpers/texting';
import { fetchCheckoutAction } from 'modules/ScrShop/store/actions';
import { filterAndSortImagesBySpread } from 'modules/ScrPhotobook/helpers/sortImagesBySpreadOrder';
import { getImageOrientations } from 'modules/ScrPhotobook/helpers/getImageOrientations';
import { getFrameOrientations } from 'modules/ScrPhotobook/helpers/getFrameOrientations';
import { findBestMatchingLayout } from 'modules/ScrPhotobook/helpers/findBestMatchingLayout';
import { findNextLayoutGroup } from 'modules/ScrPhotobook/helpers/findNextLayoutGroup';
import { repositionImagesAccordingToFrames } from 'modules/ScrPhotobook/helpers/repositionImagesAccordingToFrames';
import { getInitialFrameLayout } from 'modules/ScrPhotobook/helpers/getInitialFrameLayout';
import { excludeCoverSpreadAndId } from 'modules/ScrPhotobook/helpers/exludeCoverSpreadAndId';

interface ICreatePhotobook {
  title: string;
  slug: string;
  _product: string;
  redirectAction: (id: string) => void;
}

export const showSuccessNotify = () => {
  toast.success(getTranslationKey('photobook.savedSuccess'), {
    theme: 'colored',
    autoClose: 1000,
    hideProgressBar: true
  });
};

export const showErrorNotify = () => {
  toast.error(getTranslationKey('errorMessage'), {
    theme: 'colored',
    autoClose: 1000,
    hideProgressBar: true
  });
};

export const fetchPhotobooks = createAsyncThunk(
  'fetchPhotobooks',
  async ({ enableToast = false }: { enableToast?: boolean }) => {
    try {
      const response = await Api.Photobook.fetchPhotobooks();

      const availablePhotobooks = response.result.photobooks.filter(
        (photobook) => !photobook.isDeleted
      );

      const { purchasedPhotobooks, suggestedPhotobooks } = response.result;

      if (enableToast) {
        showSuccessNotify();
      }

      return { availablePhotobooks, purchasedPhotobooks, suggestedPhotobooks };
    } catch (e) {
      console.log('fetchPhotobooks error: ', e);
    }
  }
);

export const fetchPhotobook = createAsyncThunk(
  'fetchPhotobook',
  async ({ enableToast = false, id }: { id: string; enableToast?: boolean }) => {
    try {
      const response = await Api.Photobook.fetchPhotobook(id);

      if (enableToast) {
        showSuccessNotify();
      }

      return { photobook: response.result };
    } catch (e) {
      console.log('fetchPhotobook error: ', e);
    }
  }
);

export const createPhotobook = createAsyncThunk(
  'createPhotobook',
  async ({ title, slug, _product, redirectAction }: ICreatePhotobook, { dispatch }) => {
    try {
      const response = await Api.Photobook.createPhotobook({ title, slug, _product });

      await dispatch(fetchPhotobooks({}));
      redirectAction(response.result._id);

      return response.result;
    } catch (e) {
      showErrorNotify();
      console.log('createPhotobook error: ', e);
    }
  }
);

export const deletePhotobook = createAsyncThunk(
  'deletePhotobook',
  async (id: string, { dispatch }) => {
    try {
      await Api.Photobook.deletePhotobook(id);

      dispatch(fetchPhotobooks({ enableToast: true }));

      return id;
    } catch (e) {
      showErrorNotify();
      console.log('deletePhotobook error: ', e);
    }
  }
);

export const updatePhotobookTitle = createAsyncThunk(
  'updatePhotobookTitle',
  async ({ id, title, slug }: { id: string; title: string; slug: string }, { dispatch }) => {
    try {
      dispatch(changePhotobookTitle({ id, value: title }));

      const { result, timestamp } = await Api.Photobook.updatePhotobookTitle(id, title, slug);

      dispatch(setLastUpdate(timestamp));

      return { result, timestamp };
    } catch (e) {
      showErrorNotify();
      console.log('deletePhotobook error: ', e);
    }
  }
);

export const updatePhotobookLayout = createAsyncThunk(
  'updatePhotobookLayout',
  async (
    { photobookId, layoutId }: { photobookId: string; layoutId: string },
    { getState, dispatch }
  ) => {
    try {
      // @ts-ignore
      const { photobook } = getState();
      const { layouts = [] } = photobook;

      const currentPhotobook = photobook.photobooks.find((item) => item._id === photobookId);
      const { title, slug } = currentPhotobook;

      const currentSpreadIdx = photobook.activePageIdx - 1;

      const spreads = excludeCoverSpreadAndId(currentPhotobook.spreads);

      const currentLayout = layouts.find((layout) => layout._id === layoutId);

      const currentSpread = spreads[currentSpreadIdx];
      currentSpread.frameLayout = layoutId;
      currentSpread.images = currentSpread.images
        .sort((a, b) => a.frameIndex - b.frameIndex)
        .slice(0, currentLayout.numberOfFrames)
        .filter((image) => image.frameIndex <= currentLayout.numberOfFrames - 1);

      dispatch(updateSpreads({ photobookId, spreads }));

      const { result, timestamp } = await Api.Photobook.updatePhotobookSpreads(
        photobookId,
        title,
        slug,
        spreads
      );

      dispatch(fetchPhotobooks({}));
      dispatch(setLastUpdate(timestamp));

      return result;
    } catch (e) {
      showErrorNotify();
      console.log('updatePhotobookLayout error: ', e);
    }
  }
);

export const addNewSpread = createAsyncThunk(
  'addNewSpread',
  async (
    { photobookId, callback }: { photobookId: string; callback: () => void },
    { getState, dispatch }
  ) => {
    try {
      // @ts-ignore
      const { photobook } = getState();

      const currentPhotobook = photobook.photobooks.find((item) => item._id === photobookId);
      const { title, slug } = currentPhotobook;

      const spreads = excludeCoverSpreadAndId(currentPhotobook.spreads);

      spreads.push({ index: spreads.length, frameLayout: null, images: [] });

      dispatch(updateSpreads({ photobookId, spreads }));

      callback?.();

      const { result, timestamp } = await Api.Photobook.updatePhotobookSpreads(
        photobookId,
        title,
        slug,
        spreads
      );

      dispatch(updatePricing({ photobookId, pricing: result.pricing }));
      dispatch(fetchCheckoutAction());
      dispatch(setLastUpdate(timestamp));

      return result;
    } catch (e) {
      showErrorNotify();
      console.log('addNewSpread error: ', e);
    }
  }
);

export const deleteSpread = createAsyncThunk(
  'deleteSpread',
  async ({ photobookId }: { photobookId: string }, { getState, dispatch }) => {
    try {
      // @ts-ignore
      const { photobook } = getState();

      const currentPhotobook = photobook.photobooks.find((item) => item._id === photobookId);
      const { title, slug } = currentPhotobook;

      const currentSpreadIdx = photobook.activePageIdx - 1;

      // const currentGroup = products.groups.find((group) => slug.includes(group.slug));
      // const currentProduct = currentGroup.products.find((product) => product._id === _product);

      const spreads = excludeCoverSpreadAndId(currentPhotobook.spreads);

      spreads.splice(currentSpreadIdx, 1);

      // const minSpreadsAmount = currentProduct.pagesIncluded / 2;
      const minSpreadsAmount = 5;

      if (spreads.length < minSpreadsAmount) {
        toast.error(getTranslationKey('photobook.minPagesError', { spreads: minSpreadsAmount }), {
          theme: 'colored',
          autoClose: 4000,
          hideProgressBar: true
        });
        spreads.push({ index: spreads.length, frameLayout: null, images: [] });
      }

      const spreadsWithUpdatedIndexes = spreads.map((spread, index) => ({ ...spread, index }));

      dispatch(updateSpreads({ photobookId, spreads: spreadsWithUpdatedIndexes }));

      const { result, timestamp } = await Api.Photobook.updatePhotobookSpreads(
        photobookId,
        title,
        slug,
        spreadsWithUpdatedIndexes
      );

      dispatch(updatePricing({ photobookId, pricing: result.pricing }));
      dispatch(fetchCheckoutAction());
      dispatch(setLastUpdate(timestamp));

      return result;
    } catch (e) {
      showErrorNotify();
      console.log('deleteSpread error: ', e);
    }
  }
);

export const addImageToThePage = createAsyncThunk(
  'addImageToThePage',
  async (
    {
      photobookId,
      imageId,
      frameIndex,
      frameLayout,
      numberOfFrames
    }: {
      photobookId: string;
      imageId: string;
      frameIndex: number | null;
      frameLayout: string | null;
      numberOfFrames: number;
    },
    { getState, dispatch }
  ) => {
    try {
      // @ts-ignore
      const { photobook, products, images = [] } = getState();
      const { layouts = [] } = photobook;

      const currentSpreadIdx = photobook.activePageIdx - 1;

      const currentPhotobook = photobook.photobooks.find((item) => item._id === photobookId);
      const { title, slug, _product } = currentPhotobook;

      const currentGroup = products.groups.find((group) => slug.includes(group.slug));
      const { dimensions } = currentGroup.products.find((product) => product._id === _product);

      const spreads = excludeCoverSpreadAndId(currentPhotobook.spreads);

      const currentSpread = spreads[currentSpreadIdx];

      const newImage = {
        id: imageId,
        frameIndex,
        boundsRelative: { widthPercent: 100, heightPercent: 100, leftPercent: 50, topPercent: 50 }
      };

      if (!frameLayout) {
        const bestMatchingLayout = getInitialFrameLayout({
          layouts,
          images,
          newImage
        });

        if (bestMatchingLayout) {
          currentSpread.frameLayout = bestMatchingLayout._id;
        }
      }

      const positionsArray = currentSpread.images.map((item) => item.frameIndex).sort();
      const referenceArray = range(0, numberOfFrames);

      const missingPositions = referenceArray.filter((item) => !positionsArray.includes(item));

      if (!frameIndex) {
        const [firstMissingPosition] = missingPositions;
        newImage.frameIndex = firstMissingPosition;
      }

      if (!frameIndex && !missingPositions.length) {
        newImage.frameIndex = numberOfFrames;

        const spreadImages = [...currentSpread.images, newImage] as ISpreadImage[];

        const currentImages = filterAndSortImagesBySpread(images.images, spreadImages);
        const currentImagesOrientations = getImageOrientations(currentImages);

        const nextLayoutGroup = findNextLayoutGroup(layouts, numberOfFrames);
        const nextGroupFrames = getFrameOrientations(nextLayoutGroup, dimensions);

        const nextGroupFrameOrientations = nextGroupFrames.map((frames) =>
          frames.map((frame) => frame.orientation)
        );

        const { bestMatchingLayout, bestMatchIndex } = findBestMatchingLayout(
          currentImagesOrientations,
          nextLayoutGroup,
          nextGroupFrameOrientations,
          nextGroupFrames
        );

        if (bestMatchingLayout) {
          currentSpread.frameLayout = bestMatchingLayout._id;
          const bestMatchingArray = nextGroupFrameOrientations[bestMatchIndex];

          const repositionedImages = repositionImagesAccordingToFrames({
            spreadImages,
            bestMatchingArray,
            currentImagesOrientations
          });

          currentSpread.images = repositionedImages;
        }
      } else {
        currentSpread.images.push(newImage);
      }

      dispatch(updateSpreads({ photobookId, spreads }));

      const { result, timestamp } = await Api.Photobook.updatePhotobookSpreads(
        photobookId,
        title,
        slug,
        spreads
      );

      dispatch(setLastUpdate(timestamp));

      return result;
    } catch (e) {
      showErrorNotify();
      console.log('addImageToThePage error: ', e);
    }
  }
);

export const reuploadExisitngSpreads = createAsyncThunk(
  'reuploadSpreads',
  async ({ photobookId }: { photobookId: string }, { getState, dispatch }) => {
    try {
      // @ts-ignore
      const { photobook } = getState();

      const currentPhotobook = photobook.photobooks.find((item) => item._id === photobookId);

      const spreads = excludeCoverSpreadAndId(currentPhotobook.spreads);

      // @ts-ignore
      const { result, timestamp } = await Api.Photobook.updatePhotobookSpreads(
        photobookId,
        currentPhotobook.title,
        currentPhotobook.slug,
        spreads
      );

      dispatch(setLastUpdate(timestamp));

      return result;
    } catch (e) {
      showErrorNotify();
      console.log('reuploadImages error: ', e);
    }
  }
);

export const reuploadPreviousState = createAsyncThunk(
  'reuploadPreviousState',
  async (
    { photobook, photobookId }: { photobook: IPhotobookState; photobookId: string },
    { dispatch }
  ) => {
    try {
      const currentPhotobook = photobook.photobooks.find((item) => item._id === photobookId);

      if (!currentPhotobook) return;
      const { title, spreads, productDetails } = currentPhotobook;

      const excludedSpreads = excludeCoverSpreadAndId(spreads);

      dispatch(changePhotobookTitle({ id: photobookId, value: title }));
      dispatch(updateSpreads({ photobookId, spreads: excludedSpreads }));
      dispatch(setPhotobookCover({ photobookId, cover: productDetails.color }));
      dispatch(
        setPhotobookCoverText({
          photobookId,
          coverTextPosition: productDetails.coverTextPosition,
          coverFirstTitle: productDetails.coverFirstTitle,
          coverSecondTitle: productDetails.coverSecondTitle,
          coverFirstTitleFont: productDetails.coverFirstTitleFont,
          coverSecondTitleFont: productDetails.coverSecondTitleFont
        })
      );

      // @ts-ignore
      const { result, timestamp } = await Api.Photobook.reuploadPhotobook(
        photobookId,
        currentPhotobook.title,
        currentPhotobook.slug,
        excludedSpreads,
        productDetails
      );

      // dispatch(fetchPhotobooks({}));
      dispatch(setLastUpdate(timestamp));

      return result;
    } catch (e) {
      showErrorNotify();
      console.log('reuploadPreviousState error: ', e);
    }
  }
);

export const replaceImage = createAsyncThunk(
  'replaceImage',
  async (
    {
      photobookId,
      frameIndex,
      imageId
    }: { photobookId: string; frameIndex: number; imageId: string },
    { getState, dispatch }
  ) => {
    try {
      // @ts-ignore
      const { photobook } = getState();

      const currentSpreadIdx = photobook.activePageIdx - 1;

      const currentPhotobook = photobook.photobooks.find((item) => item._id === photobookId);
      const { title, slug } = currentPhotobook;

      const spreads = excludeCoverSpreadAndId(currentPhotobook.spreads);

      const currentImage = spreads[currentSpreadIdx].images.find(
        (image) => image.frameIndex === frameIndex
      );
      currentImage.id = imageId;
      currentImage.boundsRelative = {
        widthPercent: 100,
        heightPercent: 100,
        leftPercent: 50,
        topPercent: 50
      };

      dispatch(updateSpreads({ photobookId, spreads }));

      const { result, timestamp } = await Api.Photobook.updatePhotobookSpreads(
        photobookId,
        title,
        slug,
        spreads
      );

      dispatch(setLastUpdate(timestamp));

      return result;
    } catch (e) {
      showErrorNotify();
      console.log('addImageToThePage error: ', e);
    }
  }
);

export const updateImageBgPosition = createAsyncThunk(
  'updateImageBgPosition',
  async (
    {
      photobookId,
      frameIndex,
      xPercent,
      yPercent,
      widthPercent,
      heightPercent
    }: {
      photobookId: string;
      frameIndex: number;
      xPercent: number;
      yPercent: number;
      widthPercent: number;
      heightPercent: number;
    },
    { getState, dispatch }
  ) => {
    try {
      // @ts-ignore
      const { photobook } = getState();

      const currentSpreadIdx = photobook.activePageIdx - 1;

      const currentPhotobook = photobook.photobooks.find((item) => item._id === photobookId);
      const { title, slug } = currentPhotobook;

      const spreads = excludeCoverSpreadAndId(currentPhotobook.spreads);

      const currentSpread = spreads[currentSpreadIdx];

      const currentImage = currentSpread.images.find((image) => image.frameIndex === frameIndex);

      currentImage.boundsRelative = {
        widthPercent,
        heightPercent,
        leftPercent: xPercent,
        topPercent: yPercent
      };

      dispatch(updateSpreads({ photobookId, spreads }));

      const { result, timestamp } = await Api.Photobook.updatePhotobookSpreads(
        photobookId,
        title,
        slug,
        spreads
      );

      dispatch(setLastUpdate(timestamp));

      return result;
    } catch (e) {
      showErrorNotify();
      console.log('updateImageBgPosition error: ', e);
    }
  }
);

export const fetchLayouts = createAsyncThunk(
  'fetchLayouts',
  async (params?: { aspectRatioX: number; aspectRatioY: number; photobookId?: string }) => {
    try {
      const { result } = await Api.Photobook.fetchLayouts(
        params?.aspectRatioX,
        params?.aspectRatioY,
        params?.photobookId
      );

      return result;
    } catch (e) {
      console.log('fetchLayouts error: ', e);
    }
  }
);

export const deleteImage = createAsyncThunk(
  'deleteImage',
  async ({ photobookId }: { photobookId: string }, { getState, dispatch }) => {
    try {
      // @ts-ignore
      const { photobook } = getState();

      const currentSpreadIdx = photobook.activePageIdx - 1;
      const { activatedImageId } = photobook;
      const currentActivatedImageId = activatedImageId.split('_').shift();

      const currentPhotobook = photobook.photobooks.find((item) => item._id === photobookId);
      const { title, slug } = currentPhotobook;

      const spreads = excludeCoverSpreadAndId(currentPhotobook.spreads);

      const currentImageIdx = spreads[currentSpreadIdx].images.findIndex(
        (image) => image.id === currentActivatedImageId
      );

      if (currentImageIdx < 0) return;

      spreads[currentSpreadIdx].images.splice(currentImageIdx, 1);

      dispatch(updateSpreads({ photobookId, spreads }));

      const { result, timestamp } = await Api.Photobook.updatePhotobookSpreads(
        photobookId,
        title,
        slug,
        spreads
      );

      dispatch(setActiveImageId({ activatedImageId: '' }));
      dispatch(setLastUpdate(timestamp));

      return result;
    } catch (e) {
      showErrorNotify();
      console.log('addImageToThePage error: ', e);
    }
  }
);

export const swapImage = createAsyncThunk(
  'swapImage',
  async (
    {
      photobookId,
      swapTargetIdx,
      withoutClearing
    }: { photobookId: string; swapTargetIdx: number; withoutClearing?: boolean },
    { getState, dispatch }
  ) => {
    try {
      // @ts-ignore
      const { photobook } = getState();

      dispatch(setPhotobookMode(''));

      const currentSpreadIdx = photobook.activePageIdx - 1;
      const { activatedImageId } = photobook;
      const [, , currentImageFrameIndex = 0] = activatedImageId.split('_');

      const currentPhotobook = photobook.photobooks.find((item) => item._id === photobookId);
      const { title, slug } = currentPhotobook;

      const spreads = excludeCoverSpreadAndId(currentPhotobook.spreads);

      const currentSpread = spreads[currentSpreadIdx];

      const currentImage = currentSpread.images.find(
        (image) => image.frameIndex === Number(currentImageFrameIndex)
      );
      const targetImage = currentSpread.images.find((image) => image.frameIndex === swapTargetIdx);

      if (!currentImage || !targetImage) return;

      // Swap the frameIndex of the two images
      const tempFrameIndex = currentImage.frameIndex;
      currentImage.frameIndex = targetImage.frameIndex;
      targetImage.frameIndex = tempFrameIndex;

      // The images array should be sorted based on the frameIndex
      currentSpread.images.sort((a, b) => a.frameIndex - b.frameIndex);

      dispatch(updateSpreads({ photobookId, spreads }));

      const { result, timestamp } = await Api.Photobook.updatePhotobookSpreads(
        photobookId,
        title,
        slug,
        spreads
      );

      if (!withoutClearing) {
        dispatch(setActiveImageId({ activatedImageId: '' }));
      }
      dispatch(setLastUpdate(timestamp));

      return result;
    } catch (e) {
      showErrorNotify();
      console.log('addImageToThePage error: ', e);
    }
  }
);

export const updateCover = createAsyncThunk(
  'updateCover',
  async (
    {
      photobookId,
      cover
    }: {
      photobookId: string;
      cover: string;
    },
    { dispatch }
  ) => {
    try {
      dispatch(setPhotobookCover({ photobookId, cover }));

      const response = await Api.Photobook.updateCover(photobookId, { color: cover });

      return response.result;
    } catch (e) {
      showErrorNotify();
      console.log('updateCover error: ', e);
    }
  }
);

export const updateCoverPosition = createAsyncThunk(
  'updateCoverPosition',
  async (
    {
      photobookId,
      coverTextPosition
    }: {
      photobookId: string;
      coverTextPosition: CoverPositions;
    },
    { dispatch }
  ) => {
    try {
      dispatch(setPhotobookCoverText({ photobookId, coverTextPosition }));

      const response = await Api.Photobook.updateCoverText(photobookId, {
        coverTextPosition
      });

      return response.result;
    } catch (e) {
      showErrorNotify();
      console.log('updateCoverPosition error: ', e);
    }
  }
);

export const updateCoverTitles = createAsyncThunk(
  'updateCoverTitles',
  async (
    {
      photobookId,
      coverFirstTitle = '',
      coverSecondTitle = ''
    }: {
      photobookId: string;
      coverFirstTitle?: string;
      coverSecondTitle?: string;
    },
    { dispatch }
  ) => {
    try {
      dispatch(setPhotobookCoverText({ photobookId, coverFirstTitle, coverSecondTitle }));

      const response = await Api.Photobook.updateCoverText(photobookId, {
        coverFirstTitle: coverFirstTitle || '',
        coverSecondTitle: coverSecondTitle || ''
      });

      dispatch(setStartingTitle({ coverFirstTitle, coverSecondTitle }));

      return response.result;
    } catch (e) {
      showErrorNotify();
      console.log('updateCoverTitles error: ', e);
    }
  }
);

export const updateCoverFonts = createAsyncThunk(
  'updateCoverFonts',
  async (
    {
      photobookId,
      coverFirstTitleFont = '',
      coverSecondTitleFont = ''
    }: {
      photobookId: string;
      coverFirstTitleFont?: string;
      coverSecondTitleFont?: string;
    },
    { dispatch }
  ) => {
    try {
      dispatch(setPhotobookCoverText({ photobookId, coverFirstTitleFont, coverSecondTitleFont }));

      const response = await Api.Photobook.updateCoverText(photobookId, {
        ...(coverFirstTitleFont && { coverFirstTitleFont }),
        ...(coverSecondTitleFont && { coverSecondTitleFont })
      });

      return response.result;
    } catch (e) {
      showErrorNotify();
      console.log('updateCoverTitlesFonts error: ', e);
    }
  }
);

export const fetchPreviewPhotobook = createAsyncThunk(
  'fetchPreviewPhotobook',
  async (id: string) => {
    try {
      const { result } = await Api.Photobook.getPreviewPhotobook(id);

      return result;
    } catch (e) {
      console.log('fetch preview photobook error: ', e);
    }
  }
);
